<template>
  <a-button
    class="float-right mb-3 d-flex align-items-center"
    type="primary"
    @click="showForm = !showForm"
  >
    <i class="bi bi-plus mr-1 mb-1"></i>
    Add Labeler
  </a-button>

  <labeler-list :list="list"></labeler-list>

  <a-modal
    v-model:visible="showForm"
    centered
    :closable="false"
    :footer="null"
    title="Add Labeler"
    destroyOnClose
  >
    <add-labeler :roles="roles" @addLabeler="addLabeler" @cancel="toggleModal">
    </add-labeler>
  </a-modal>
</template>
<script>
import LabelerList from './LabelerList.vue';
import AddLabeler from './AddLabeler.vue';
import httpClient from '../../../../service/httpClient';
import LabelerServices from '../../../../services/labeler'
import UserService from 'src/services/user';

export default {
  components: {
    LabelerList,
    AddLabeler,
  },
  inject: ['toast'],

  data() {
    return {
      list: [],
      roles: [],
      showForm: false,
    };
  },

  created() {
    this.getLabelersList();
    this.fetchRoles();
  },

  methods: {
    async getLabelersList() {
      const [error, data] = await LabelerServices.getLabelersList()
      if (error) {
        this.toast.error('Error occured while fetching labelers!')
        return
      }
      this.list = data
    },

    async fetchRoles() {
       const [error, data] = await UserService.allRoles()
      if (error) {
        this.toast.error('Error occured while fetching Roles!')
        return
      }
      this.roles = data
    },

    async addLabeler(data) {
      const res = await httpClient.register('account/register', data);
      if (res === 'error') {
        this.toast.error('Error occured while fetching Roles!');
        return;
      }
      this.showForm = false;
      this.getLabelersList();
    },
    toggleModal() {
      this.showForm = !this.showForm;
    },
  },
};
</script>
<style></style>
