import apiHelper from './index';

const getLabelersList = async () => {
  return await apiHelper.get(`account/users_list?role__name=labeler`);
};

const getAssignedTask = async (id, spinner = true) => {
  return await apiHelper.get(
    `cloud_source_labeling/assigned_task/${id}`,
    spinner
  );
};

const deleteAssignedTask = async (payload, spinner = true) => {
  return await apiHelper.patch(
    `cloud_source_labeling/unassign_labeler`,
    payload,
    spinner
  );
};

export default {
  getLabelersList,
  getAssignedTask,
  deleteAssignedTask,
};
